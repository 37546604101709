/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Tarik Yilmaz",
  title: "Hi all, I'm Tarik",
  subTitle: (
    " I am a software developer aiming to specialize in AI backend development 🚀. I work as AI Engineer, Data Scientist and Data Engineer in EU projects, work with data modeling and develop projects in AWS cloud environments. By integrating these projects into backend systems, I offer innovative solutions."
  ),
  resumeLink:
    "https://drive.google.com/deneme", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  linkedin: "https://www.linkedin.com/in/muhammet-tar%C4%B1k-y%C4%B1lmaz/",
  gmail: "contact@yilmaztarik.com",
  kaggle: "https://www.kaggle.com/muhammettarkylmaz",
  medium: "https://medium.com/@tarikkyilmmaz",
  github: "https://github.com/TarikYil",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "SOME OF THE TECHNOLOGIES I WORK ON",
  skills: [
    emoji(
      "⚡ Using Python, AWS, Spark, Kafka, SQL databases, Docker, MongoDB and Jenkins for high-performance, modular and scalable backend systems development."
    ),
    emoji("⚡  Development and integration of models with TensorFlow, scikit-learn, Keras and MLflow for machine learning and artificial intelligence applications."),
    emoji(
      "⚡ Creating advanced image processing and analysis solutions, performing operations on complex image data using OpenCV."
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },

    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "spark",
      fontAwesomeClassname: "fas fa-bolt"
    },
    {
      skillName: "kafka",
      fontAwesomeClassname: "fas fa-stream" 
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "tensorflow",
      fontAwesomeClassname: "fas fa-brain"
    },
    {
      skillName: "scikit-learn",
      fontAwesomeClassname: "fas fa-chart-line"
    },
    {
      skillName: "keras",
      fontAwesomeClassname: "fas fa-layer-group"
    },
    {
      skillName: "opencv",
      fontAwesomeClassname: "fas fa-eye"
    },
    {
      skillName: "mongodb",
      fontAwesomeClassname: "fas fa-leaf"
    },
    {
      skillName: "mlflow",
      fontAwesomeClassname: "fas fa-project-diagram"  // Önerilen ikon
    },
    {
      skillName: "jenkins",
      fontAwesomeClassname: "fas fa-tools"  // Önerilen ikon
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Sakarya Applied Sciences University",
      logo: require("./assets/images/EN-mavi_düz_logo.png"),
      subHeader: "Bachelor of Science in Electrical Electronic Engineering",
      duration: "Aug 2019 - July  2023",
      desc: "Took courses about Software Engineering, Cloud Computing (AWS), Artificial Intelligence, Data Science and Engineering...",
      descBullets: ["I have been involved in many projects during my engineering education. Some are autonomous robot design, unmanned aerial vehicle software."]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "AI & Backend Developer",
      company: "Ino Robotics",
      companylogo: require("./assets/images/inor.jpeg"),
      date: "September 2023 – Present",
      desc: "I work on AI platform development, live data processing and model training platforms, synthetic image generation and European Union projects. In this context, I develop innovative artificial intelligence solutions, build real-time data processing systems, provide advanced model training and specialize in synthetic data production. In addition, taking part in the projects supported by the European Union, I am working on effective and sustainable artificial intelligence applications in the international arena.",
    },
    {
      role: "Data Scientist Intern",
      company: "INOSENS",
      companylogo: require("./assets/images/İNO.png"),
      date: "March  2023 – June  2023",
      desc: "Data visualization was done, fine-tuning was performed on the created model, and studies on WANDB were observed."
    },
    {
      role: "AI Engineer Intern",
      company: "Hamle Technology Group",
      companylogo: require("./assets/images/ham.jpeg"),
      date: "February  2023 – June 2023",
      desc: "A camera personal protective equipment control tracking software was created using the YOLOV5 artificial intelligence model, which was trained by tagging over 20 thousand photos to determine personnel and personal protective equipment, and the detected outliers were recorded in the database."
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  projects: [
    {
      image: require("./assets/images/opeva.jpeg"),
      projectName: "OPEVA - OPtimization of Electric Vehicle Autonomy",
      projectDesc: "The main objective of the OPEVA project is to increase the market penetration and widespread acceptance of electric vehicles (EVs) by addressing limiting psychological factors such as range anxiety, high price, limited charging facilities and charging time. The project will be coordinated by the Permm Development and co-funded by Key Digital Technology Joint Undertaking, the Horizon Europe programme and the national authorities of the participating countries.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://opeva.eu/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/TAS.png"),
      projectName: "TASTI: Application-Tailored Synthetic Image Generation",
      projectDesc: "The main goal of the TASTI project is to develop a modular technology transfer framework that can innovate and customize synthetic image production to applications. This framework will be displayed in four different industry areas: healthcare, automotive industry, materials manufacturing and agriculture.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://eureka-xecs.com/all-projects/xecs-call-1/tasti/"
        }
      ]
    },
    {
      image: require("./assets/images/efi.png"),
      projectName: "EFICAS",
      projectDesc: "EFICAS provides a general and scalable software platform that supports the energy efficient deployment of AI algorithms to meet the growing demand for power due to increased functional complexity in mobile and autonomous applications. This platform supports native and distributed computing settings as well as cloud offline. It also handles hybrid-compatible operation with on-time resource allocation and design-time optimized task allocation.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.eficas.eu/"
        }
      ]
    },
    {
      image: require("./assets/images/uswa.png"),
      projectName: "USWA: Ultra Scalable Wireless Access",
      projectDesc: "The USWA project explores DECT-2020 NR radio technology for a variety of industrial use cases. This technology supports the mesh radio network architecture, which can operate on a license-free band and create large-scale networks. Within the scope of the project, applications such as QoS monitoring of the electrical network and status monitoring in industrial facilities are carried out, which offers the possibility of switching to renewable energy and more accurate process monitoring.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.celtic-next-uswa.org/"
        }
      ]
    },
    {
      image: require("./assets/images/prev.jpeg"),
      projectName: "PReVLA",
      projectDesc: "PReVLA aims to develop personalized and intelligent telerehabilitation services for Parkinson's patients and post-stroke patients under the Eureka Network program. The project aims to improve the effectiveness of remote rehabilitation sessions and treatment programs using new privacy-sensitive sensor technologies. Rehabilitation services are designed to include tele-rehabilitation services for swallowing disorder and tele-rehabilitation services for musculoskeletal exercises. PReVLA aims to provide a cost-effective and comprehensive solution built on seamless remote interaction and monitoring.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.linkedin.com/posts/prevla-project_eureka-network-linkedin-activity-7020667718571802624-v-pB/?trk=public_profile_like_view"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),

  achievementsCards: [
    {
      title: "Miuul MLOps (Machine Learning Operations) Bootcamp",
      subtitle:
        "The program begins with the introduction of MLOps and ML engineering, then covers API development and ML model distribution processes with FastAPI, ending with the packaging of codes and automation techniques.",
      image: require("./assets/images/MİUUL.jpeg"),
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/1lSXR9LQUbA1QuUBHp0Zeel8Yxz8WjfSb/view?usp=sharing"
        }
      ]
    },
    {
      title: "NVIDIA Fundamentals of Deep Learning",
      subtitle:
        "This program is a workshop that allows Participants to showcase their competencies in the Deep Learning Basics.",
      image: require("./assets/images/nvdiajpg.jpg"),
      footerLink: [
        {
          name: "Certification",
          url: "https://learn.nvidia.com/certificates?id=4d46e0ab2c134470a5bc3f2b4aaaab4f"
        }
      ]
    },

    {
      title: "Coursera Introduction to Machine Learning on AWS",
      subtitle: "This course describes the differences between artificial intelligence, machine learning and deep learning, teaches how to choose the appropriate AWS machine learning service for a specific use case, and how to build machine learning models, it gives information that it will be trained and distributed.",
      image: require("./assets/images/cour.png"),
      footerLink: [
        
        {
          name: "Certification",
          url: "https://www.coursera.org/account/accomplishments/verify/CC5CL4MN2EZQ?utm_source=link&utm_medium=certificate&utm_content=cert_image&utm_campaign=pdf_header_button&utm_product=course"
        }
      ]
    },
    {
      title: "Miuul Data Science and Machine Learning Bootcamp",
      subtitle: "Data Science and Machine Learning Bootcamp, AB Testing, CRM Analytics, expected sales and profit forecasts, RFM and Churn analysis, recommendation systems, property engineering, etc, it aims to increase the competences of the participants in these fields by providing training in machine learning and SQL topics.",
      image: require("./assets/images/MİUUL.jpeg"),
      footerLink: [
        
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/1-XorHbjuyzO3W0YTWUtoRFmigZcxZw3F/view?usp=sharing"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://medium.com/@tarikkyilmmaz/mount-data-889f3a3a7f06",
      title: "Mount Data Link and Transfer from EC2 to S3",
      description:
        "Recent updates have made it possible to quickly connect from the EC2 machine to the S3 bucket. This has allowed some database applications to be eliminated for data generated on the EC2 machine."
    },
    {
      url: "https://www.linkedin.com/pulse/advanced-python-asynchronous-programming-tar%2525C4%2525B1k-y%2525C4%2525B1lmaz-genef/?trackingId=dHEYyatISZq7fwkjSrO9rw%3D%3D",
      title: "Advanced Python Asynchronous Programming",
      description:
        "Learn about asynchronous programming, a critical skill for Advanced Python applications. This article on Python's asyncio library and techniques using FastAPI explains how to maximize FastAPI's performance and effectively manage CPU-intensive processes."
    },
    {
      url: "https://medium.com/@tarikkyilmmaz/nedir-bu-amazon-s3-simple-storage-service-37ee8a48e48f",
      title: "What is Amazon S3?",
      description:
        "It is a cloud storage service from Amazon. S3 allows users to store and access large amounts of data over the internet. This data can range from text files, photos, videos to application data."
    },
    {
      url: "https://www.linkedin.com/pulse/synthetic-image-generation-stable-diffusion-model-tar%2525C4%2525B1k-y%2525C4%2525B1lmaz-ritwf/?trackingId=nFKf%2Bu%2FMTXW7EWbsf6nkDA%3D%3D",
      title: "Synthetic Image Generation with Stable Diffusion Model",
      description:
        " We focused on the Stable Diffusion and LoRA models. These technologies increase our efficiency while enhancing our customized solutions. In particular, the LoRA model stands out for its flexibility and Stable Diffusion for its customization capacity."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  email_address: "contact@yilmaztarik.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};
